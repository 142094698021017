export const TERMINALS_NAME = [
    "Unknown",
    "S1F2",
    "P400"
]

export const TERMINALS = {
    0:0,
    1: 28,
    2: 28,
    3: 28,
}